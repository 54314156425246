<template>
    <v-container>
        <!-- <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h2 class="text-h3 font-weight-light">Start your free trial</h2>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-5">
            <v-col style="text-align: center">
                <p class="text-body-1 font-weight-light">This will be quick. Credit card is <strong>NOT</strong> required.</p>
            </v-col>
        </v-row> -->
        <!-- <QuickStartSteps :highlight="highlight" :current="step"/> -->

        <!-- TODO: if we move the entire "app" website to behind raccoon oasis itself, then user will never see this because
        the guard service will redirect non-authenticated users to the login service, so we can remove this section and the
        corresponding logic in the init() function, and also remove the login() function because the guard service will already
        include the return URL when it redirects the user, so login service wlil redirect back here after login;  if user didn't
        even have an account, login service would redirect user to hello service to register, and include the same "next" url,
        so the hello service will redirect the user here after they sign up -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="isViewReady && !isAuthenticated && !error">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="4">
                    <v-toolbar short flat>
                        <v-toolbar-title>Log in to continue</v-toolbar-title>
                    </v-toolbar>
                    <v-card-actions>
                        <v-btn text @click.prevent="login" color="teal">Log in</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- webauthz prompt form -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="isViewReady && isAuthenticated && !error">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <template v-if="!accountId">
                    <AccountViewList :list="accountList" select-one @selected="onSelectAccount"/>
                </template>
                <template v-if="accountId && !openvpnConfigId">
                    <OpenvpnConfigViewList :accountId="accountId" :list="openvpnConfigList" select-one create @selected="onSelectOpenvpnConfig"/>
                </template>
                <template v-if="accountId && openvpnConfigId">
                <v-card elevation="4">
                    <v-toolbar short flat>
                        <v-toolbar-title>Grant access to application</v-toolbar-title>
                    </v-toolbar>
                    <!-- <v-card-text class="text-h6 text-center">
                        <span>Grant access to application</span>
                    </v-card-text> -->
                    <v-form v-model="promptForm" ref="promptFormRef" @submit="validatePromptForm" onSubmit="return false;" @keyup.enter.native="validatePromptForm">
                        <v-row justify="center" class="px-5 py-5" no-gutters>
                            <v-col>
                                <p class="font-weight-light text-center">
                                    <span class="font-weight-bold">{{ clientName }}</span>
                                    <template v-if="grantMethod === 'redirect'">
                                        (<span class="font-weight-bold"><a :href="clientURI" target="_blank">{{ clientDomain }}</a></span>)
                                    </template>
                                    <template v-if="grantMethod === 'text' && clientURI !== 'Application'">
                                        (<span class="font-weight-bold">{{ clientURI }}</span>)
                                    </template>
                                </p>
                                <p class="font-weight-light text-center">
                                    <span class="text-body-2">{{ clientVersion }}</span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="px-5 pb-5" v-if="scope === 'openvpn-setup'" no-gutters>
                            <p class="font-weight-light text-center">The application is requesting permission to use the OpenVPN configuration
                                <span class="font-weight-bold">{{ openvpnConfig.label }}</span>
                                in the account
                                <span class="font-weight-bold">{{ account.name }}</span>.
                            </p>
                            <!-- <p class="font-weight-light">
                                Enterprise account:
                                <span class="font-weight-bold">{{ targetAccount.name }}</span>
                            </p>
                            <p class="font-weight-light">
                                Realm:
                                <span class="font-weight-bold">{{ targetRealm.label }}</span>
                                (<span class="font-weight-bold">{{ targetRealm.domain }}</span>)
                            </p> -->
                        </v-row>

                        <!-- <v-row justify="center" class="px-5 py-5" v-if="targetAccountId">
                            <v-list class="ma-0 pa-0">
                                <v-list-item class="lighten-5" style="padding-left: 8px;">
                                    <v-list-item-avatar tile style="margin-right: 8px;">
                                        <Avatar :attr="targetAccount" :size="32"/>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ targetAccount.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ targetAccount.type }}</v-list-item-subtitle>
                                        <v-list-item-content v-if="targetRealmId">
                                            {{ targetRealm.label }} ({{ targetRealm.domain }})
                                        </v-list-item-content>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-row> -->

                        <v-row justify="center" class="px-5" v-if="isGranted" no-gutters>
                            <p class="font-weight-bold">You granted this access.</p>
                            <!-- TODO: show information or link on how to revoke the access -->
                        </v-row>
                        <v-row justify="center" class="px-5" v-if="isDenied" no-gutters>
                            <v-col class="pa-0">
                                <p class="font-weight-bold">You denied this access.</p>
                                <p class="font-weight-light">To grant access, return to the application and start a new request.</p>
                            </v-col>
                        </v-row>

                        <!-- <v-row justify="center" class="px-5 py-5" v-if="isPrompt">
                            <v-alert dense type="warning" border="left">
                                <template v-slot:prepend>
                                    <font-awesome-icon icon="exclamation-triangle" class="white--text"/>
                                </template>
                                <span class="ml-2">Check the application name and domain -- are they correct?</span>
                            </v-alert>
                        </v-row> -->

                        <v-row justify="center" v-if="isPrompt" no-gutters class="px-2">
                        <v-card-actions>
                            <v-btn elevation="4" @click="onGrantAccess()" :disabled="!isViewReady" class="blue white--text">
                                <span>Continue</span>
                            </v-btn>
                            <v-btn @click="onDenyAccess()" :disabled="!isViewReady" text class="grey--text">
                                <span>Cancel</span>
                            </v-btn>
                        </v-card-actions>
                        </v-row>
                        <v-row justify="center" v-show="isActionPending" no-gutters class="px-2">
                            <p class="text-h5 font-weight-light">Saving...</p>
                        </v-row>

                        <template v-if="redirect">
                            <v-row justify="center" no-gutters class="px-2">
                                <p class="text-body-1 font-weight-light"><a :href="redirect">Continue to application</a></p>
                            </v-row>
                        </template>
                        <template v-if="grantText">
                            <v-row justify="center" no-gutters class="px-2">
                                <p class="text-body-1 font-weight-light">Copy the following text to the application:</p>
                            </v-row>
                            <v-row justify="center" no-gutters class="px-2 py-2">
                                <v-col cols="12">
                                <v-textarea outlined v-model="grantText" ref="grantTextRef" readonly auto-grow></v-textarea>
                                <v-btn text @click="copyGrantText" color="teal">Copy to clipboard</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-form>
                </v-card>
                </template>
            </v-col>
        </v-row>

        <!-- error message -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="error">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <p class="red--text text-center">We cannot process this request.</p>
                <p class="text-center"><a href="/contact/?topic=support">Contact support</a></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
// import { client } from '@/client';
import AccountViewList from '@/components/AccountViewList.vue';
import OpenvpnConfigViewList from '@/components/OpenvpnConfigViewList.vue';

export default {
    components: {
        AccountViewList,
        OpenvpnConfigViewList,
    },
    data() {
        return {
            accountId: null,
            accountList: [],
            openvpnConfigId: null, // for openvpn-setup scope
            openvpnConfigList: null, // indicates it hasn't been loaded yet; when it's loaded it will be an array
            // interactionId: null,
            requestId: null,
            promptForm: false, // true when all field inputs are ready to submit
            isEnterpriseRealm: false, // true when application is requesting access to an enterprise realm
            scope: null,
            clientName: null,
            clientVersion: null,
            clientURI: null,
            clientDomain: null,
            grantMethod: null,
            targetAccountId: null,
            targetAccount: null, // object with account info
            targetRealmId: null,
            targetRealm: null, // object with realm info
            isViewReady: false, // true when prompt information is loaded from server
            isActionPending: false, // true while sending grant to server
            isPrompt: false, // true when prompting the user to decide
            isGranted: false, // true when loading a prompt that was already completed with action: grant
            isDenied: false, // true when loading a prompt that was already completed with action: deny
            redirect: null, // string (url) when a redirect back to the application is available
            grantText: null, // string (text) when user needs to copy and paste this to the application
            error: false,
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
            accountMap: (state) => state.accountMap,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isGrantFormComplete() {
            return true;
        },
        account() {
            return this.accountId ? this.accountList.find((item) => item.id === this.accountId) : null;
        },
        openvpnConfig() {
            return this.openvpnConfigId && this.openvpnConfigList ? this.openvpnConfigList.find((item) => item.id === this.openvpnConfigId) : null;
        },
    },
    methods: {
        async init() {
            if (!this.isAuthenticated) {
                // the view will show a 'login required' message with a link to login
                this.isViewReady = true;
                return;
            }

            this.requestId = this.$route.query.id;
            this.accountId = this.$route.query.accountId;
            this.openvpnConfigId = this.$route.query.openvpnConfigId;

            try {
                if (!this.$route.query.accountId) {
                    await this.loadAccountList();
                    this.isViewReady = true;
                    return; // will redirect to same url with accountId parameter, then we'll load the prompt
                }
            } catch (err) {
                console.error('failed to load account list', err);
            }

            try {
                if (!this.$route.query.openvpnConfigId) {
                    await this.loadOpenvpnConfigList();
                    this.isViewReady = true;
                    return;
                }
            } catch (err) {
                console.error('failed to load openvpn config list', err);
            }

            try {
                if (this.$route.query.id) {
                    await this.loadWebauthzAccessPrompt();
                } else {
                    console.error('webauthz/prompt.vue: init, request id is missing');
                    this.error = true;
                    this.isViewReady = true;
                    return;
                }
            } catch (err) {
                console.error('init failed', err);
                this.error = true;
            }
        },
        login() {
            this.$router.push({ name: 'login', query: { next: this.$route.fullPath } });
        },
        async loadWebauthzAccessPrompt() {
            try {
                this.$store.commit('loading', { getAccessPrompt: true });
                // console.log('webauthz/prompt.vue: init, interactionId: %s', this.interactionId);
                console.log('webauthz/prompt.vue: init, requestId: %s', this.requestId);
                // console.log('webauthz/prompt.vue: init, client: %o', client);
                // console.log('webauthz/prompt.vue: init, client.webauthz: %o', client.webauthz);
                if (this.requestId) {
                    // const { type, state } = await this.$store.dispatch('loadInteraction', this.interactionId); // TODO: use a webauthz specific API for this instead of interaction? something like await this.$client.webauthz.getAccessPrompt(this.webauthzRequestId);
                    const {
                        /* id, */ client_name: clientName, client_version: clientVersion, client_origin: clientURI, grant_method: grantMethod, realm, scope, status, created_on: createdOn, not_after: notAfter,
                    } = await this.$client.webauthz(this.$route.params.realmId).getAccessPrompt(this.requestId);
                    if (realm === 'Webauthz' && scope === 'openvpn-setup') {
                        await this.prepareWebauthzPrompt({
                            realm,
                            scope,
                            status,
                            clientName,
                            clientVersion,
                            clientURI, // TODO: this can also be 'Application' (for now) so maybe need a separate field , like we only need clientURI / origin if the grant_method is 'redirect', otherwise we could just display 'Application' and not try to parse it
                            grantMethod,
                            createdOn,
                            notAfter,
                        });
                    } else {
                        console.log('webauthz/prompt.vue: invalid request');
                        this.error = true;
                    }
                } else {
                    console.log('webauthz/prompt.vue: interaction id required');
                    this.error = true;
                }
            } catch (err) {
                // TODO: handle errors like {"fault":"request expired"} and show a more specific error message for things that user can solve themselves (by starting over maybe)
                console.error('init failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { getAccessPrompt: false });
            }
        },
        async prepareWebauthzPrompt({
        /* clientId, clientState, realm, */ scope, status, clientName, clientVersion, clientURI, grantMethod, /* accountId, realmId, */
        } = {}) {
            console.log(`prepareWebauthzPrompt: scope ${scope} status ${status} clientName ${clientName} clientVersion ${clientVersion} clientURI ${clientURI}`); /* accountId ${accountId} realmId ${realmId} */
            this.scope = scope;
            this.clientName = clientName || 'Unknown application';
            this.clientVersion = clientVersion;
            this.clientURI = clientURI;
            this.grantMethod = grantMethod;
            // TODO:  need to show account selection !!!
            // this.targetAccountId = accountId;
            // this.targetRealmId = realmId;
            // TODO: need to get grant_method from server so we know if it's a URL to parse or not
            if (grantMethod === 'redirect' && clientURI) {
                try {
                    const url = new URL(clientURI);
                    this.clientDomain = url.host;
                } catch (err) {
                    console.error('prepareWebauthzPrompt failed to parse client uri', err);
                    this.clientDomain = clientURI;
                }
            } else {
                this.clientDomain = clientURI; // e.g. 'Application'  // TODO:  in UI template above,  display this as plain text and NOT a LINK
            }
            switch (scope) {
            case 'openvpn-setup':
                // this.isEnterpriseRealm = true;
                // // load the account and realm info
                // if (!accountId || !realmId) {
                //     console.error('prepareWebauthzPrompt: accountId and realmId required for scope enterprise-realm');
                //     this.error = true;
                //     break;
                // }

                // /*
                // try {
                //     this.$store.commit('loading', { loadAccountById: true });
                //     this.targetAccount = await this.$client.account(this.$route.params.accountId).currentAccount.get(accountId);
                // } catch (err) {
                //     console.log('init: cannot load message', err);
                // } finally {
                //     this.$store.commit('loading', { loadAccountById: false });
                // }
                // */
                // this.targetAccount = this.accountMap[accountId];

                // try {
                //     this.$store.commit('loading', { loadRealmById: true });
                //     this.targetRealm = await this.$client.realm.getById(realmId);
                // } catch (err) {
                //     console.log('init: cannot load realm info', err);
                // } finally {
                //     this.$store.commit('loading', { loadRealmById: false });
                // }
                break;
            default:
                console.error(`webauthz/prompt.vue: unknown scope ${scope}`);
                this.error = true;
                break;
            }
            switch (status) {
            case 'deny':
                console.error('webauthz/prompt.vue: webauthz request already completed');
                this.isDenied = true;
                break;
            case 'grant':
                console.error('webauthz/prompt.vue: webauthz request already completed');
                this.isGranted = true;
                break;
            case 'start':
                this.isPrompt = true;
                break;
            case 'pending':
            case 'prompt':
                this.isPrompt = true;
                break;
            default:
                console.error(`webauthz/prompt.vue: invalid interaction status ${status}`);
                this.error = true;
                break;
            }
            this.isViewReady = true;
        },
        validatePromptForm() {
            if (this.$refs.promptFormRef.validate()) {
                this.onGrantAccess();
            }
        },
        async onGrantAccess() {
            console.log('onGrantAccess');
            if (this.isGrantFormComplete) {
                console.log('onGrantAccess: form complete');

                // const grantRequest = {
                //     action: 'grant',
                // };

                let grantResponse;
                // send to server
                try {
                    this.isActionPending = true;
                    this.$store.commit('loading', { grantAccess: true });
                    // const grantResponse = await this.$client.main().interaction.edit(this.interactionId, grantRequest); // TODO: use a webauthz specific API instead of interaction? like  await this.$client.webauthz.grantAccess(this.webauthzRequestId);
                    // const grantResponse = await this.$client.webauthz.editAccessRequest(this.requestId, grantRequest);
                    const permit = {};
                    switch (this.scope) {
                    case 'openvpn-setup':
                        permit.accountId = this.accountId;
                        permit.openvpnConfigId = this.openvpnConfigId;
                        break;
                    default:
                        permit.accountId = this.accountId;
                        break;
                    }
                    grantResponse = await this.$client.webauthz(this.$route.params.realmId).grantAccess(this.requestId, permit);
                } catch (err) {
                    console.log('grant request failed: %o', err);
                    this.error = true;
                } finally {
                    this.isActionPending = false;
                    this.$store.commit('loading', { grantAccess: false });
                }

                // update UI
                this.isPrompt = false;
                if (grantResponse.grant_method === 'redirect' && typeof grantResponse.redirect === 'string') {
                    this.redirect = grantResponse.redirect; // make a link available in case window.location change is prevented
                    window.location = grantResponse.redirect;
                } else if (grantResponse.grant_method === 'text' && typeof grantResponse.text === 'string') {
                    this.grantText = grantResponse.text;
                } else {
                    console.log('grant request failed with response: %o', grantResponse);
                }
            }
        },
        async onDenyAccess() {
            console.log('onDenyAccess');
            if (this.isGrantFormComplete) {
                console.log('onDenyAccess: form complete');

                // const denyRequest = {
                //     action: 'deny',
                // };

                let denyResponse;
                // send to server
                try {
                    this.isActionPending = true;
                    this.$store.commit('loading', { onSubmitOptin: true });
                    // const denyResponse = await this.$client.main().interaction.edit(this.interactionId, denyRequest); // TODO: // TODO: use a webauthz specific API instead of interaction? like  await this.$client.webauthz.denyAccess(this.webauthzRequestId);
                    // const denyResponse = await this.$client.webauthz.editAccessRequest(this.requestId, denyRequest);
                    denyResponse = await this.$client.webauthz(this.$route.params.realmId).denyAccess(this.requestId);
                } catch (err) {
                    console.log('deny request failed: %o', err);
                    this.error = true;
                } finally {
                    this.isActionPending = false;
                    this.$store.commit('loading', { onSubmitOptin: false });
                }

                // update UI
                this.isPrompt = false;
                if (denyResponse.grant_method === 'redirect' && typeof denyResponse.redirect === 'string') {
                    this.redirect = denyResponse.redirect; // make a link available in case window.location change is prevented
                    window.location = denyResponse.redirect;
                } else if (denyResponse.grant_method === 'text' && typeof denyResponse.text === 'string') {
                    this.grantText = denyResponse.text;
                } else {
                    console.log('deny request failed with response: %o', denyResponse);
                }
            }
        },
        copyGrantText() {
            const input = this.$refs.grantTextRef.$el.querySelector('textarea');
            input.focus();
            input.select();
            input.setSelectionRange(0, input.value.length);
            document.execCommand('copy');
            this.$bus.$emit('snackbar', { type: 'info', message: 'Copied to clipboard' });
        },
        async loadAccountList() {
            return []; // TODO: no accounts in this service, need to skip the account selection step
            // const result = await this.$client.user(this.$store.state.session.userId).user.getAccountList();
            // console.log(`Prompt.vue loadAccountList result: ${JSON.stringify(result)}`);
            // if (result && result.list) {
            //     this.accountList = result.list;
            //     if (this.accountList.length === 1) {
            //         this.onSelectAccount({ accountId: this.accountList[0].id });
            //     }
            // }
        },
        onSelectAccount({ accountId }) {
            this.$router.push({ name: 'webauthz-prompt', query: { id: this.requestId, accountId } });
        },
        async loadOpenvpnConfigList() {
            return []; // TODO: no openvpn configs in this service, but if we're going to load something it would be with await this.$client.something(this.$route.params.realmId).list(...)
            // const result = await this.$client.openvpnConfig.list({ accountId: this.accountId });
            // console.log(`Prompt.vue loadOpenvpnConfigList result: ${JSON.stringify(result)}`);
            // if (result && result.list) {
            //     this.openvpnConfigList = result.list;
            // }
        },
        onSelectOpenvpnConfig({ openvpnConfigId }) {
            this.$router.push({ name: 'webauthz-prompt', query: { id: this.requestId, accountId: this.accountId, openvpnConfigId } });
        },
    },

    watch: {
        isAuthenticatedReady(value, oldValue) {
            console.log('quickstart.vue: isAuthenticatedReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
        // when we redirect user to same view to add an account id in onSelectAccount(),
        // it stays mounted so we need to watch for the route change here
        // mounted() won't be called again
        $route(value, oldValue) {
            let isChanged = false;
            if (value.query.accountId !== oldValue.query.accountId) {
                this.accountId = value.query.accountId;
                isChanged = true;
            }
            if (value.query.openvpnConfigId !== oldValue.query.openvpnConfigId) {
                this.openvpnConfigId = value.query.openvpnConfigId;
                isChanged = true;
            }
            if (isChanged) {
                this.init();
            }
        },
    },

    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
